:root {
  --bg-color: #22272D;
  --text: #F5EDE0;
  --headers: #BFD7EA;
  --highlight: #F44E27;
  --shadow: 1px 2px 5px var(--bg-color);
}

.App {
  width: 90%;
  margin:0 auto;
}

section{
  padding:40px 0;
}

h2{
  margin-bottom: 10px;
  color: var(--bg-color);
}

.viewAll{
  width: 100%;
  margin-top:30px;
  text-align: center;
}

.viewAllBtn{
  width:100%;
  display: inline;
  padding:10px 15px;
  color: var(--text);
  background-color:var(--highlight);
  border-radius: 5px;
  box-shadow: var(--shadow);
  transition: all 0.3s;
}

.viewAllBtn:hover{
  color: var(--headers);
  background-color:var(--bg-color);
}

/* ABOUT */

.aboutMe{
  margin:40px 0;
  background-color: var(--bg-color);
  border-radius:5px;
  color:var(--text);
  padding:40px 20px;
  box-shadow: var(--shadow);
}

.aboutMe h4{
  font-weight:normal;
  margin-bottom: 25px;
}

.socials{
  text-align: center;
  list-style-type: none;
}

.socials li, .project-links-container li{
  display: inline;
  margin: 0 10px ;
}

/* LATEST POSTS */
article{
  box-shadow: var(--shadow);
  background-color: var(--bg-color);
  border-radius: 5px;
  margin-bottom:30px;
  padding: 20px;
  transition: all 0.3s;
}

article:hover{
  transform: scale(1.01);
}

article a{
  width:100%;
  display: block;
  color: var(--text);
}

article h4{
  font-size: 1.2em;
  color: var(--headers);
}

.date{
  font-size: 12px;
}

/* ACHIEVEMENTS */

.timeline-description h2{
  margin-top: 40px;
  font-size: 1.6em;
}

.timelineContainer{
  /* max-width: 340px;
  width: 100%; */
  border-radius: 5px;
  text-align: center;
  padding:5px;
  box-shadow: 1px 1px 6px var(--bg-color);
}

.timelineContainer a{
  transition: all 0.3s;
  text-decoration: underline;
}

.timelineContainer a:hover{
  color: var(--highlight);
}

.timeline-header{
  color:var(--text);
}

.headerContainer p:nth-of-type(2){
  font-size: 0.8em;
  padding:5px 0;
  letter-spacing: 1px;
}


/*  SKILLS */

.skills{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:5px;
  grid-row-gap: 15px;
  text-align: center;
}

.skills div{
  background-color: #22272D;
  max-width:200px;
  width:100%;
  padding: 15px 0;
  margin:0 auto;
  border-radius: 5px;
  box-shadow: var(--shadow);
}

.skills p{
  font-size:.9em;
  color:#EEE0CB;
}

/* FOOTER */
footer {
  width: 100%;
  padding:40px 0;
  text-align: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

/* BLOG POST */

.blog-post h1, 
.blog-post h2,
.blog-post h3,
.blog-post h4{
  color: var(--bg-color);
  padding:10px 0;
}

.blog-post code{
  color:#E167A1;
}

.blog-post hr{
  background-color: var(--bg-color);
  margin:10px 0;
}

.blog-post ul{
  list-style-type: disc;
  padding:10px 30px;
}

.blog-post li{
  padding:5px 0;
}

.blog-post img{
  padding:20px 0 10px 0;
  max-width: 700px;
  width:100%;
  border-radius: 5px;
  text-align: center;
}

.blog-post a{
  color:var(--highlight);
  text-decoration: underline;
}

.blog-project-links a{
  display: inline-block;
  padding:5px 10px;
  border-radius: 5px;
  font-size: 0.7em;
  font-weight:bold;
  margin:0 20px 10px 0;
  box-shadow: 0 0 1px var(--bg-color);
  transition: all 0.3s;
}

.blog-project-links a:hover{
  background-color: var(--highlight);
  color: var(--text);
}

.blog-project-links svg{
  position: relative;
  top:1px;
}

.blog-post pre{
  border-radius: 5px;
}

@media only screen and (max-width: 425px) {
  .App {
    width: 100%;
    padding:0 10px;
    margin:0 auto;
  }

  .timeline-desc{
    font-size: 14px;
  }
  

}

@media only screen and (max-width: 768px) {

  .timeline{
    padding:0 !important;
  }

  .timeline-item{
    margin: 0 !important; 
  }

  .timeline-content{
    /* padding:0 !important; */
  }

}

@media only screen and (min-width: 1024px) {

  body{
      width:85%;
      margin:0 auto;
  }

  .project-Container{
      width: 100%;
      max-width: 740px;
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: 1fr 1fr;
  }

  .skills{
      display: grid;
      grid-template-columns:1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1850px) {
  .skills{
      display: grid;
      grid-template-columns:1fr 1fr 1fr 1fr;
    }
}