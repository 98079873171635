:root {
    --bg-color: #22272D;
    --text: #F5EDE0;
    --headers: #BFD7EA;
    --highlight: #F44E27;
    --shadow: 1px 4px 5px var(--bg-color);
  }

/* Individual Project */
hr{
    background-color:#BFD7EA;
    height:2px;
    border:none;
}

h3{
    text-align: center;
    padding-bottom:10px;
}

.project-card{
    max-width: 400px;
    width:100%;
    background-color: var(--bg-color);
    color: var(--text);
    padding:20px 20px 30px 20px;
    margin:0  auto 30px auto;
    border-radius: 5px;
    position: relative;
    box-shadow: var(--shadow);
}

.project-card p{
    text-align:center;
    font-size: 16px;
    padding:10px 0;
}

.project-card a{
    color: #000;
}

.project-links-container{
    text-align: center;
    margin:10px 0;
}

.project-links-container a{
    display: inline-block;
    border: 1px solid var(--headers);
    padding:3px 10px;
    border-radius: 5px;
    color: var(--headers);
}

.project-links{
    transition: all 0.3s;
}

.disabled-link{
    cursor: not-allowed;
    display: inline-block;
    border: 1px solid var(--headers);
    padding:3px 13px;
    font-size: 1.01em;
    border-radius: 5px;
    background-color: var(--bg-color);
    color: var(--headers);
    text-decoration: line-through;
}

.project-links:hover{
    background-color: var(--highlight);
    color:var(--text);
}

.tags{
    /* width: 100%; */
    font-size: 0.8em;
    position: absolute;
    bottom:0;
    padding:5px 0;
    text-align: center;
}

.wip{
    background-color: var(--text);
    color: var(--highlight);
    padding: 5px 10px;
    position: absolute;
    right:20px;
    top:10px;
}


/* Project Container */
.project-Container{
    width: 100%;
    margin:0 auto;
}