/* NAVBAR */
.desktop-nav{
    padding:80px 0 0 0;
  }

.desktop-nav li {
  display: inline;
  margin-right:30px ;
  margin-left: 0;
}

.desktop-nav a {
  font-size: 18px;
  transition: all 0.3s;
}

.desktop-nav a:hover{
  color:#F44E27;
}



/* ul {
  list-style-type: none;
}

li{
 display: inline;
  margin: 0 10px ;
} */
  
.bm-burger-button{
  display: none;
}
  
  
  /*  MENU PACKAGE */
  /* Position and sizing of burger button */
  @media only screen and (max-width: 800px) {

    .desktop-nav{
      display: none;
    }
  
    .bm-burger-button {
      display: block;
      position: fixed;
      width: 36px;
      height: 30px;
      right: 36px;
      top: 36px;
    }
  
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #F44E27;
    }
    
    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #a90000;
    }
    
    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
    
    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }
    
    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      margin-top: -40px;
    }
    
    /* General sidebar styles */
    .bm-menu {
      background: #373a47;
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
    
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }
    
    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 0.8em;
      height:auto !important;
      position: absolute;
      bottom:0;
    }
    
    /* Individual item */
    .bm-item {
      display: inline-block;
      color: #fff;
      transition: all 0.3s;
    }

    .bm-item:hover{
      color: #F44E27;
    }
    
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }